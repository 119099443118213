.group[data-v-7ae391a6] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  margin-bottom: 8px;
}
.group .group-item[data-v-7ae391a6] {
    margin: 0 0 0 8px;
}
.group .group-item-right[data-v-7ae391a6] {
    position: inherit;
}
