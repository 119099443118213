[data-v-61146141] .flow-config-form {
  height: 700px;
}
[data-v-61146141] .el-divider__text {
  color: #f4bb21;
  font-weight: 700;
  font-size: var(--rootFontSize);
}
.custom-tree-node[data-v-61146141] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: var(--rootFontSize);
  padding-right: 8px;
}
.box-card[data-v-61146141] {
  margin-bottom: 15px;
}
.clearfix[data-v-61146141] {
  font-size: var(--rootFontSize);
  font-weight: 700;
}
[data-v-61146141] .params-item-type .cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: var(--rootFontSize);
}
[data-v-61146141] .params-item-type .cell .el-select {
    width: calc(100% - 30px);
}
[data-v-61146141] .params-item-type .cell .el-icon-setting {
    width: 30px;
    font-size: 22px;
    color: #409eff;
    margin-left: 10px;
}
[data-v-61146141] .params-item-type .cell .cus-treeselect {
    font-size: var(--rootFontSize);
}
[data-v-61146141] .params-item-type .cell .cus-treeselect .vue-treeselect__control {
      height: 32px;
      line-height: 32px;
}
[data-v-61146141] .params-item-type .cell .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
        margin-bottom: 3px;
}
[data-v-61146141] .params-item-type .cell .cus-treeselect .vue-treeselect__control .vue-treeselect__single-value,[data-v-61146141] .params-item-type .cell .cus-treeselect .vue-treeselect__control .vue-treeselect__placeholder {
        line-height: 32px;
}
[data-v-61146141] .params-item-type .cell .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
        line-height: 24px;
}
[data-v-61146141] .params-item-value .cell .cus-treeselect {
  font-size: var(--rootFontSize);
}
[data-v-61146141] .params-item-value .cell .cus-treeselect .vue-treeselect__control {
    height: 32px;
    line-height: 32px;
    padding: 0px 5px 0px 12px;
}
[data-v-61146141] .params-item-value .cell .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
      margin-bottom: 3px;
}
[data-v-61146141] .params-item-value .cell .cus-treeselect .vue-treeselect__control .vue-treeselect__single-value,[data-v-61146141] .params-item-value .cell .cus-treeselect .vue-treeselect__control .vue-treeselect__placeholder {
      line-height: 32px;
}
[data-v-61146141] .params-item-value .cell .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
      line-height: 24px;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body {
  min-height: 300px;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container {
    width: 100%;
    height: 100%;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      border-top: 1px solid #f1f4f6;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background-color: #f5f7fa;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-header .node-action-header-item {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        text-align: center;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-content {
      width: 100%;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-content .node-action-row {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        border-top: 1px solid #f1f4f6;
        padding: 4px 0px;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-content .node-action-row:first-of-type {
          border-top: none;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-content .node-action-row .node-action-item {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
          text-align: center;
          padding: 4px 10px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-content .node-action-row .node-action-item .el-button {
            font-size: 14px !important;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-tree {
      height: 300px;
      overflow-y: auto;
      width: 100%;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-tree .el-tree-node__content {
        height: auto;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-tree .node-action-row {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        border-top: 1px solid #f1f4f6;
        padding: 10px 10px;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-tree .node-action-row:first-of-type {
          border-top: none;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .node-action-tree .node-action-row .node-action-item {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
          padding: 4px 10px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          text-align: center;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .add-btn-wrapper {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px dotted #ccc;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      cursor: pointer;
      margin-top: 14px;
}
[data-v-61146141] .datasource-config-dlg .el-dialog__body .node-action-container .add-btn-wrapper i {
        font-size: var(--rootFontSize3);
        color: #409eff;
        line-height: inherit;
}
